import React from 'react'

import SEO from '../../components/seo'
import CertificationsContent from '../../components/Certifications/Certifications'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { CERTIFICATIONS as CERTIFICATIONS_LINK } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
} from '../../lib/generate-schema'
import defaultFaqs from '../../components/FAQs/faqs-list'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Certifications',
    url: CERTIFICATIONS_LINK,
  },
]

const Certifications: React.FC = () => {
  return (
    <div>
      <SEO
        title="Certifications: Read About RNG &amp; AIGF Membership Certificate | Mega"
        description="Read about the certifications of Mega. Mega hold a RNG certificates and AIGF member. Click to know why Mega the safest and most fair platform to play!"
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(defaultFaqs)}
      />
      <CertificationsContent showFaqs={false} title="100% Certified Platform" />
    </div>
  )
}

export default Certifications
