export const formatForBigQuery = (params: { [index: string]: any }) => {
  const allParams: { [index: string]: any } = {
    ...params,
  }

  return Object.keys(allParams).map(key => ({
    key,
    value: allParams[key],
  }))
}

export const isMobile = () =>
  typeof window !== 'undefined' &&
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

export const isIOS = () =>
  typeof window !== 'undefined' && /iPhone|iPad|iPod/i.test(navigator.userAgent)

export const isAndroid = () =>
  typeof window !== 'undefined' && /Android/i.test(navigator.userAgent)
